import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useState } from 'react'
import {
  SubmitHandler,
  UseFormRegister,
  UseFormSetValue,
  useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PopulationType } from '../../containers/ClinicPatients/tables/interfaces'
import { createPopulation } from '../../core/api/create-population'
import {
  InputField,
  InputFieldLabel,
  InputFieldWrapper,
  InputWrapper
} from '../FormStyles/FormStyles'
import { ModalComponent } from '../Modal/Modal'

interface CreatePopulationNodalProps {
  closeModal: () => void
  refresh: () => void
  updateContext: () => void
}

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  width: 31.25rem;
  gap: 2rem;
`

const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const SubmitButton = styled.button`
  border: 0.0625rem solid var(--brand-primary-color);
  width: 100%;
  padding: 1rem;
  background-color:var(--brand-primary-color);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: inter;
  color: var(--white-color);
  text-transform: uppercase;
  cursor: pointer;
  &:disabled {
    opacity: 0.16;
    cursor: default;
    border: 0.0625rem solid rgb(0,0,0,0);
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  width: 100%;
  box-sizing: border-box;
`
const InfoBoxIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  font-weight: 900;
  color: var(--yellow);
`
const InfoBoxText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--yellow-darker);
`
interface FormValues {
  name: string
  description: string
  type: PopulationType
}
interface InputComponentProps {
  label: 'name' | 'description'
  register: UseFormRegister<FormValues>
  resetError: Dispatch<SetStateAction<Error | undefined>>
  errorState?: Error | undefined
  setValue: UseFormSetValue<any>
  getValues: (field?: string | string[] | undefined) => any
}
const InfoBoxComponent = () => {
  const { t } = useTranslation()
  return (
    <InfoBox data-testid="createPopulationInfoBox">
      <InfoBoxIcon icon={faTriangleExclamation}></InfoBoxIcon>
      <InfoBoxText>
        {t('An error occurred when creating the population')}
      </InfoBoxText>
    </InfoBox>
  )
}

const InputComponent = ({
  label,
  register,
  errorState
}: InputComponentProps) => {
  const { t } = useTranslation()
  const getLabel = (label: 'name' | 'description') => {
    switch (label) {
      case 'name':
        return t('Name')
      case 'description':
        return t('Description')
    }
  }
  const getPlaceHolder = (label: 'name' | 'description') => {
    switch (label) {
      case 'name':
        return t('enter_population_name')
      case 'description':
        return t('enter_population_description')
    }
  }
  return (
    <InputWrapper>
      <InputFieldLabel
        style={
          errorState !== undefined ? { color: 'var(--yellow-darker)' } : {}
        }
      >
        {getLabel(label)}
      </InputFieldLabel>
      <InputFieldWrapper>
        <InputField
          data-testid={`createPopulationInput${label}`}
          style={
            errorState !== undefined ? { borderColor: 'var(--yellow)' } : {}
          }
          {...register(label, {
            required: true
          })}
          placeholder={getPlaceHolder(label)}
          autoComplete="off"
        ></InputField>
      </InputFieldWrapper>
    </InputWrapper>
  )
}
export const CreatePopulationModal = ({
  closeModal,
  refresh,
  updateContext
}: CreatePopulationNodalProps) => {
  const [errorState, setErrorState] = useState<Error | undefined>()

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: ''
    }
  })
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await createPopulation({
        name: data.name,
        description: data.description,
        type: PopulationType.DiaTrust
      })
      closeModal()
      refresh()
      updateContext()
    } catch (err) {
      setErrorState(new Error())
    }
  }
  return (
    <ModalComponent
      closeFun={closeModal}
      closetestid="closeCreatePopulationModal"
    >
      <Wrapper
        onClick={function (event) {
          event.stopPropagation()
        }}
        data-testid="createPopulationModal"
      >
        <Title>{t('Create new population')}</Title>
        {errorState !== undefined && <InfoBoxComponent />}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputComponent
            label={'name'}
            register={register}
            resetError={setErrorState}
            errorState={errorState}
            setValue={setValue}
            getValues={getValues}
          />
          <InputComponent
            label={'description'}
            register={register}
            resetError={setErrorState}
            setValue={setValue}
            getValues={getValues}
          />
          <SubmitButton
            disabled={!isValid}
            type={'submit'}
            data-testid="createPopulationButton"
          >
            {t('create population')}
          </SubmitButton>
        </Form>
      </Wrapper>
    </ModalComponent>
  )
}

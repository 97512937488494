import { t } from 'i18next'
import { useState } from 'react'
import styled from 'styled-components'
import { FitbitActivityMaxes } from '../../../../../containers/StackedDaily/Interfaces/Interfaces'
import dayjs from '../../../../../core/dayjs/dayjs'
import {
  ActivityGraph,
  ActivityGraphSize
} from '../../../../ActivityComponents/ActivityGraph/ActivityGraph'
import { GlucoseGraph } from '../../../GlucoseGraph/GlucoseGraph'
import { PenInjectionsGraph } from '../../../PenInjections/PenInjections'
import {
  InsightTooltipProps,
  PenInjectionTooltip
} from '../../../PenInjections/PenInjectionTooltip/PenInjectionTooltip'
import { DetectedHypo } from '../../interfaces'

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const GraphWrapper = styled.div`
  position: relative;
  width: 48.3125rem;
  height: 11.875rem;
  z-index: 2;
`

const PenInjectionsWrapper = styled(GraphWrapper)`
  height: 8.125rem;
`
const CauseWrapper = styled.div<{ $bgColor: string; $strokeColor: string }>`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  width: calc(48.3125 - 13px);
  background-color: ${(props) => props.$bgColor};
  box-sizing: border-box;
  border: ${(props) => `0.0625rem solid ${props.$strokeColor}`};
`
const CauseTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
`

const CauseKey = styled.span`
  color: var(--pink);
`
const CauseValue = styled.span`
  color: var(--text-primary);
`

const TimeStamp = styled.div`
  position: absolute;
  width: 2.6875rem;
  height: 1.25rem;
  top: -1.625rem;
  left: 27.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--veryLow-color);
  box-sizing: border-box;
  font-family: inter;
  font-size: 0.625rem;
  color: var(--veryLow-color);
  font-weight: 600;
  line-height: 0.75rem;
`
const GraphTimeStamp = styled(TimeStamp)<{ $pos: number }>`
  width: 2.25rem;
  border: 0.0625rem solid var(--text-lightest);
  color: var(--text-lighter);
  left: ${(props) => props.$pos + '%'};
  white-space: nowrap;
  transform: ${(props) =>
    `translateX(${
      props.$pos === 0 ? '0%' : props.$pos === 100 ? '-100%' : '-50%'
    })`};
`

const GraphTimeStampWrapper = styled.div`
  position: relative;
  width: calc(100% - 15px);
  margin-left: 15px;
`

const TipImage = ({
  strokeColor,
  bgColor
}: {
  strokeColor: string
  bgColor: string
}) => {
  return (
    <svg
      style={{
        position: 'absolute',
        left: 'calc(60% + 0.03125rem)',
        transform: 'translateX(-50%) translateY(-100%)',
        top: '0.03125rem'
      }}
      width="47"
      height="14"
      viewBox="0 0 47 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4516 3.0231C21.0701 0.325633 24.9795 0.325631 26.598 3.0231L31.6707 11.4777C32.4238 12.7329 33.7802 13.5008 35.2439 13.5008H45.9435C45.9789 13.5008 46.0144 13.5009 46.0498 13.5012H0C0.0354087 13.5009 0.0708436 13.5008 0.106304 13.5008H10.8056C12.2694 13.5008 13.6258 12.7329 14.3788 11.4777L19.4516 3.0231Z"
        fill={bgColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.172 12.5C34.0875 12.4752 33.0888 11.8964 32.5289 10.9632L27.4562 2.50861C25.4493 -0.836203 20.6017 -0.836203 18.5948 2.50861L13.522 10.9632C12.9621 11.8964 11.9635 12.4752 10.8789 12.5H13.5155C13.852 12.2121 14.145 11.8685 14.3795 11.4777L19.4523 3.0231C21.0708 0.325633 24.9802 0.325631 26.5987 3.0231L31.6714 11.4777C31.9059 11.8685 32.1989 12.2121 32.5354 12.5H35.172Z"
        fill={strokeColor}
      />
    </svg>
  )
}

const CauseComponent = ({
  strokeColor,
  bgColor,
  hypo
}: {
  strokeColor: string
  bgColor: string
  hypo: DetectedHypo
}) => {
  return (
    <CauseWrapper $strokeColor={strokeColor} $bgColor={bgColor}>
      <TipImage strokeColor={strokeColor} bgColor={bgColor} />
      <CauseTextWrapper>
        <CauseKey>{t('Potential cause(s)')}</CauseKey>
        <CauseValue>{hypo.hypoTimeAndCauses.causes.join(',')}</CauseValue>
      </CauseTextWrapper>
    </CauseWrapper>
  )
}
const GraphTimeStamps = () => {
  const timeStamps = [0, 2, 4, 8, 10]
  // subtracting 6 from timestamp because the starting position is 6 hours before the event timestamp.
  // the time stamps will be -6, -4, -2, +2. +4
  return (
    <>
      {timeStamps.map((timestamp) => (
        <GraphTimeStamp key={'timestamp' + timestamp} $pos={timestamp * 10}>{`${
          timestamp < 6 ? '-' : '+'
        } ${Math.abs(timestamp - 6)}${t('hour_unit_short')}`}</GraphTimeStamp>
      ))}
    </>
  )
}

export const InsightGraph = ({
  hypo,
  maxPenInjection,
  fitbitActivityMaxes
}: {
  hypo: DetectedHypo
  maxPenInjection: number | null
  fitbitActivityMaxes: FitbitActivityMaxes
}) => {
  const [penTooltip, setPenTooltip] = useState<InsightTooltipProps | null>(null)

  return (
    <GraphContainer>
      <GraphWrapper>
        <GraphTimeStampWrapper>
          <GraphTimeStamps />
        </GraphTimeStampWrapper>
        <TimeStamp>
          {dayjs(hypo.hypoTimeAndCauses.localTime).format('HH:mm')}
        </TimeStamp>
        <GlucoseGraph
          data={hypo.cgmEvents}
          insights={{
            timestamp: hypo.hypoTimeAndCauses.localTime,
            penEvents: hypo.insulinEvents
          }}
        />
      </GraphWrapper>
      <ActivityGraph
        data={hypo.azmEvents}
        idx={1}
        fitbitActivityMaxes={fitbitActivityMaxes}
        size={ActivityGraphSize.small}
        day=""
        graphIsClickable={false}
        hypo={true}
      />
      <PenInjectionsWrapper>
        {penTooltip !== null && <PenInjectionTooltip data={penTooltip.data} />}
        <PenInjectionsGraph
          data={[]}
          maxPenInjection={maxPenInjection}
          setShowPenInjectionTooltip={setPenTooltip}
          insights={{
            timestamp: hypo.hypoTimeAndCauses.localTime,
            penEvents: hypo.insulinEvents,
            setPenTooltip
          }}
        ></PenInjectionsGraph>
      </PenInjectionsWrapper>
      {/* <CauseComponent
        strokeColor="var(--veryLow-color)"
        bgColor="var(--element-bg)"
        hypo={hypo}
      /> */}
    </GraphContainer>
  )
}
